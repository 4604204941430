import PLAY_STATE, { ModeType } from '@/contants/playState';

const videoPlayer = {
    namespaced: true,
    state() {
        return {
            currentVideoInfo: localStorage.getItem('currentVideoInfo') ? JSON.parse(localStorage.getItem('currentVideoInfo')) : {},
            playState: PLAY_STATE.loading,
            playRate: 1,
            currentTime: 0,
            player: null,
            bufferTime: 0,
            modeType: ModeType.all
        }
    },
    mutations: {
        setPlayer(state, player) {
            state.player = player;
        },
        setVideoInfo(state, info) {
            state.currentVideoInfo = {
                ...state.currentVideoInfo,
                ...info
            }
            localStorage.setItem('currentVideoInfo', JSON.stringify(state.currentVideoInfo))
        },
        changeVideoInfo(state, info) {
            state.currentVideoInfo = info;
            localStorage.setItem('currentVideoInfo', JSON.stringify(info))
        },
        changePlayState(state, playState) {
            if (playState === PLAY_STATE.loaded && state.playState === PLAY_STATE.play) {
                state.playState = PLAY_STATE.play  // fix ios 状态特殊情况：先播放后load结束
            } else {
                state.playState = playState;
            }
        },
        changePlayRate(state, playRate) {
            state.playRate = playRate
        },
        changeUserPlayRate(state, playRate) {
            state.userPlayState = playRate
        },
        changeCurrentTime(state, currentTime) {
            state.currentTime = currentTime
        },
        changeBufferTime(state, bufferTime) {
            state.bufferTime = bufferTime;
        },
        changeModeType(state, modeType) {
            state.modeType = modeType;
        }
    }
}

export default videoPlayer